import {reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, nextTick, ref} from 'vue';
import SplitUtil ,{ISplitDataObj} from "./splitUtil";
export default defineComponent ({
    name: "split",
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ISplitDataObj=reactive<ISplitDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:Object.assign({lwidth:'50%',lMinWidth:10,rMinWidth:15},context.attrs)
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new SplitUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            await nextTick(()=>{
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                if(flag)dataObj.utilInst.phoneDrag();
                else dataObj.utilInst.drag();
            })
        })
        const cfgWidth=(options:any)=>{
            dataObj.utilInst.cfgWidth(options);
        }
        return{
            ...toRefs(dataObj),cfgWidth
        }
    },
    components: {}
});